// routes.ts
import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { companyRoutes } from "@/company/companyRoutes";
import { authRoute } from './features/auth/auth.routes';
import { adminRoute } from "@/admin/adminRoute";
import { playbookViewerRoutes } from '@/company/playbooks/playbook-viewer-routes';
import { conversationViewerRoutes } from '@/company/playbooks/conversation-viewer-routes';
import { companyResolver } from './resolvers/company.resolver';
import { profileResolver } from './resolvers/profile.resolver';

export const routes: Routes = [
  authRoute,
  ...playbookViewerRoutes,
  ...conversationViewerRoutes,
  {
    path: '',
    canActivate: [authGuard],
    resolve: {
      profile: profileResolver,
    },
    loadComponent: () =>
      import('./features/container/container.component').then(
        (c) => c.ContainerComponent,
      ),
    children: [
      ...adminRoute,
      {
        path: 'app/profile',
        loadComponent: () =>
          import('./features/profile/profile.component').then(
            (c) => c.ProfileComponent,
          ),
      },
      {
        path: 'app',
        loadComponent: () =>
          import('./features/company/company.component').then(
            (c) => c.CompanyComponent,
          ),
        resolve: {
          company: companyResolver,
        },
        children: companyRoutes,
      },
      {
        path: '**',
        redirectTo: 'app/playbooks',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'app/playbooks',
  },
];
