import { Routes } from '@angular/router';
import { UserRole } from '@/models/user-role.model';
import { learningCenterRedirectGuard, roleGuard } from 'app/guards/auth.guard';
import { permissionGroupGuard } from "@/company/people/guard/permission-group.guard";
export const companyRoutes: Routes = [
  {
    path: 'playbooks',
    loadComponent: () =>
      import('../company/playbooks/playbooks.component').then(
        (m) => m.PlaybooksComponent,
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './playbooks/playbook-listing/playbook-listing.component'
          ).then((c) => c.PlaybookListingComponent),
      },
    ],
  },
  {
    path: 'learning-center',
    data: {
      allowedRoles: [
        UserRole.SuperVPlaybookAdmin,
        UserRole.StandardVPlaybookAdmin,
        UserRole.Administrator,
        UserRole.SalesManager,
        UserRole.User,
      ],
    },
    canActivate: [roleGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../company/learning-center/learning-center.component').then(
            (c) => c.LearningCenterComponent,
          ),
        canActivate: [learningCenterRedirectGuard],
      },
      {
        path: 'management',
        loadComponent: () =>
          import(
            './learning-center/collection-listing-management/collection-listing-management.component'
          ).then((c) => c.CollectionListingManagementComponent),
        data: {
          allowedRoles: [
            UserRole.SuperVPlaybookAdmin,
            UserRole.StandardVPlaybookAdmin,
            UserRole.Administrator,
            UserRole.SalesManager,
          ],
        },
        canActivate: [roleGuard],
      },
      {
        path: 'user',
        loadComponent: () =>
          import(
            './learning-center/collection-listing/collection-listing.component'
          ).then((c) => c.CollectionListingComponent),
        data: {
          allowedRoles: [UserRole.SalesManager, UserRole.User],
        },
      },
      {
        path: 'management/collection/:id',
        loadComponent: () =>
          import(
            './learning-center/collection-listing-management/collection-management/collection-management.component'
          ).then((c) => c.CollectionManagementComponent),
      },
      {
        path: 'user/collection/:id',
        loadComponent: () =>
          import(
            '../company/learning-center/collection-listing/collection/collection.component'
          ).then((c) => c.CollectionComponent),
      },
      {
        path: 'feedback',
        loadComponent: () =>
          import(
            './learning-center/collection-feedback/collection-feedback.component'
          ).then((c) => c.CollectionFeedbackComponent),
      },
      {
        path: 'management/collection-templates',
        loadComponent: () =>
          import(
            './learning-center/collection-listing-management/collection-templates/collection-templates.component'
          ).then((c) => c.CollectionTemplatesComponent),
      },
      {
        path: 'management/collection-templates/collection/:id',
        data: { collectionTemplateType: 2 }, //TODO: @Dimitrije / @Sofija - think about this
        loadComponent: () =>
          import(
            './learning-center/collection-listing-management/collection-management/collection-management.component'
          ).then((c) => c.CollectionManagementComponent),
      },
      {
        path: 'collection-responses',
        loadComponent: () =>
          import(
            './learning-center/collection-responses/collection-responses.component'
          ).then((c) => c.CollectionResponsesComponent),
      },
      {
        path: 'leaderboard',
        loadComponent: () =>
          import(
            './learning-center/collection-leaderboard/collection-leaderboard.component'
          ).then((c) => c.CollectionLeaderboardComponent),
      },
      {
        path: 'leaderboard/participantBreakdown',
        loadComponent: () =>
          import(
            './learning-center/collection-leaderboard/participant-breakdown/participant-breakdown.component'
          ).then((c) => c.ParticipantBreakdownComponent),
      },
    ],
  },
  {
    path: 'people',
    loadComponent: () =>
      import(
        '@/company/people/container/people-container/people-container.component'
      ).then((c) => c.PeopleContainerComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../company/people/people.component').then(
            (c) => c.PeopleComponent,
          ),
      },
      {
        path: 'bulk-actions',
        loadComponent: () =>
          import(
            '../company/people/pages/bulk-actions/bulk-actions.component'
          ).then((c) => c.BulkActionsComponent),
      },
      {
        path: 'hierarchy-map',
        loadComponent: () =>
          import(
            '../company/people/pages/hierarchy-map/hierarchy-map.component'
          ).then((c) => c.HierarchyMapComponent),
      },
      {
        path: 'tags',
        loadComponent: () =>
          import('../company/people/people-tags/people-tags.component').then(
            (c) => c.PeopleTagsComponent,
          ),
      },
      {
        path: 'notification',
        loadComponent: () =>
          import(
            '../company/people/pages/notifications/notifications.component'
          ).then((c) => c.NotificationsComponent),
      },
      {
        path: 'custom-fields',
        loadComponent: () =>
          import(
            '@/company/people/pages/custom-fields-page/custom-fields-page.component'
          ).then((c) => c.CustomFieldsPageComponent),
      },
      {
        path: 'permission-groups',
        loadComponent: () =>
          import(
            '@/company/people/pages/permission-groups/permission-groups.component'
          ).then((c) => c.PermissionGroupsComponent),
        canActivate: [permissionGroupGuard],
      },
    ],
  },
  {
    path: 'insights',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../company/insights/insights.component').then(
            (c) => c.InsightsComponent,
          ),
      },
      {
        path: 'reports',
        loadComponent: () =>
          import(
            '../company/insights/pages/insights-reports/insights-reports.component'
          ).then((c) => c.InsightsReportsComponent),
      },
      {
        path: 'report-description',
        loadComponent: () =>
          import(
            '../admin/templates/report-description/report-description.component'
          ).then((c) => c.ReportDescriptionComponent),
      },
    ],
    loadComponent: () =>
      import(
        '@/company/insights/container/insights-container/insights-container.component'
      ).then((c) => c.InsightsContainerComponent),
  },
  {
    path: 'templates',
    loadComponent: () =>
      import('../company/templates/templates-container.component').then(
        (c) => c.TemplatesContainerComponent,
      ),
    children: [
      {
        path: '',
        redirectTo: 'conversation-template',
        pathMatch: 'full',
      },
      {
        path: 'conversation-template',
        loadComponent: () =>
          import(
            '../company/templates/conversation-template/conversation-template.component'
          ).then((c) => c.ConversationTemplateComponent),
      },
      {
        path: 'page-template',
        loadComponent: () =>
          import(
            '../company/templates/page-template/page-template.component'
          ).then((c) => c.PageTemplateComponent),
      },
      {
        path: 'playbook-template',
        loadComponent: () =>
          import(
            '../company/templates/playbook-template/playbook-template.component'
          ).then((c) => c.PlaybookTemplateComponent),
      },
    ],
  },
  {
    path: 'assets',
    loadComponent: () =>
      import(
        '../company/assets/assets-container/assets-container.component'
      ).then((c) => c.AssetsContainerComponent),
    children: [
      {
        path: '',
        redirectTo: 'assets',
        pathMatch: 'full',
      },
      {
        path: '',
        loadComponent: () =>
          import('../company/assets/assets.component').then(
            (c) => c.AssetsComponent,
          ),
      },
    ],
    data: {
      allowedRoles: [UserRole.SuperVPlaybookAdmin],
    },
    canActivate: [roleGuard],
  },
  {
    path: 'coaching',
    loadComponent: () =>
      import(
        '../company/coaching/coaching-container/coaching-container.component'
      ).then((c) => c.CoachingContainerComponent),
    data: {
      allowedRoles: [
        UserRole.SuperVPlaybookAdmin,
        UserRole.Administrator,
        UserRole.StandardVPlaybookAdmin,
        UserRole.SalesManager,
      ],
    },
    canActivate: [roleGuard],
    children: [
      {
        path: '',
        redirectTo: 'coaching',
        pathMatch: 'full',
      },
      {
        path: '',
        loadComponent: () =>
          import('../company/coaching/coaching.component').then(
            (c) => c.CoachingComponent,
          ),
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('../company/coaching/dashboard/dashboard.component').then(
            (c) => c.DashboardComponent,
          ),
        data: {
          //allowedRoles: [UserRole.SalesManager],
        },
      },
      {
        path: 'team-assessments',
        loadComponent: () =>
          import(
            '../company/coaching/team-assessments/team-assessments.component'
          ).then((c) => c.TeamAssessmentsComponent),
      },
      {
        path: 'leaderboard',
        loadComponent: () =>
          import('../company/coaching/leaderboard/leaderboard.component').then(
            (c) => c.LeaderboardComponent,
          ),
      },
      {
        path: 'featured-content',
        loadComponent: () =>
          import(
            '../company/coaching/featured-content/featured-content.component'
          ).then((c) => c.FeaturedContentComponent),
      },
      {
        path: 'challenge-responses',
        loadComponent: () =>
          import(
            '../company/coaching/challenge-responses/challenge-responses.component'
          ).then((c) => c.ChallengeResponsesComponent),
      },
      {
        path: 'feedback',
        loadComponent: () =>
          import('../company/coaching/feedback/feedback.component').then(
            (c) => c.FeedbackComponent,
          ),
      },
      {
        path: 'active-challenges',
        loadComponent: () =>
          import(
            '../company/coaching/active-challenges/active-challenges.component'
          ).then((c) => c.ActiveChallengesComponent),
      },
    ],
  },
];
