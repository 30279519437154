import { Route } from '@angular/router';
import { UserRole } from '@/models/user-role.model';
import { roleGuard } from '../../../guards/auth.guard';

export const templatesRoute: Route = {
  path: 'templates',
  loadComponent: () =>
    import('../templates/templates.component').then(
      (c) => c.TemplatesComponent,
    ),
  data: {
    allowedRoles: [UserRole.SuperVPlaybookAdmin],
  },
  canActivate: [roleGuard],
  children: [
    {
      path: '',
      redirectTo: 'page-template-groups',
      pathMatch: 'full',
    },
    {
      path: 'page-template-groups',
      loadComponent: () =>
        import(
          '../templates/admin-page-template-groups/admin-page-template-groups.component'
        ).then((c) => c.AdminPageTemplateGroupsComponent),
    },
    {
      path: 'page-template-groups/:groupId',
      loadComponent: () =>
        import(
          '../templates/admin-page-template-groups/admin-page-templates/admin-page-templates.component'
        ).then((c) => c.AdminPageTemplatesComponent),
    },
    {
      path: 'playbook-template-groups',
      loadComponent: () =>
        import(
          '../templates/playbook-template-groups/playbook-template-groups.component'
        ).then((c) => c.PlaybookTemplateGroupsComponent),
    },
    {
      path: 'playbook-template-groups/:companyId',
      loadComponent: () =>
        import(
          '../templates/playbook-template-groups/components/playbook-templates/playbook-templates.component'
        ).then((c) => c.PlaybookTemplatesComponent),
    },
    {
      path: 'reports',
      loadComponent: () =>
        import(
          '../templates/report-description/report-description.component'
        ).then((c) => c.ReportDescriptionComponent),
    },
    {
      path: 'team-assessment',
      loadComponent: () =>
        import(
          '../templates/admin-team-assessment/admin-team-assessment.component'
        ).then((c) => c.AdminTeamAssessmentComponent),
    },
    {
      path: 'platform-badges',
      loadComponent: () =>
        import('../templates/platform-badges/platform-badges.component').then(
          (c) => c.PlatformBadgesComponent,
        ),
    },
  ],
};
