import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { FontIconComponent } from './components/font-icon/font-icon.component';
import { TooltipDirective } from './directives/tooltip.directive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastContainerComponent,
    FontIconComponent,
    TooltipDirective,
  ],
})
export class AppComponent {
  title = 'vPlaybookAngular';
}
