import { CompanyService } from '@/api/company/company.service';
import { StorageService } from '@/helpers/storage/storage.service';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';

export const companyIdResolver: ResolveFn<number> = (route, state) => {
  const storageService = inject(StorageService);
  const companyService = inject(CompanyService);
  const companyId = route.paramMap.get('companyId');

  if (companyId) {
    storageService.setCompanyId(+companyId);
  } else {
    storageService.setCompany(null);
    companyService.currentCompany$.set(null);
  }
  return of(+companyId);
};
