import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StorageService } from '@/helpers/storage/storage.service';

export const permissionGroupGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const company = inject(StorageService).getCompany();
  const showPermissionGroup =
    company.enableUserTypePermissionGroup || company.allowPermissionGroups;
  if (!showPermissionGroup) {
    router.navigate(['../']);
    return false;
  }

  return true;
};
