import { ICompany } from '@/api/company/company.models';
import { CompanyService } from '@/api/company/company.service';
import { StorageService } from '@/helpers/storage/storage.service';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { tap } from 'rxjs';

export const companyResolver: ResolveFn<ICompany> = (route, state) => {
  const storageService = inject(StorageService);
  const companyService = inject(CompanyService);
  const companyId = route.data['companyId'] ?? storageService.getCompanyId();
  return companyService.get(companyId).pipe(
    tap((data: ICompany) => {
      storageService.setCompanyId(data.id);
      storageService.setCompany(data);
      companyService.currentCompany$.set(data);

      if (data.defaultButtonColor) {
        document.documentElement.style.setProperty(
          '--primary',
          data.defaultButtonColor,
        );
        document.documentElement.style.setProperty(
          '--lighter',
          adjustColor(data.defaultButtonColor, 20),
        );
        document.documentElement.style.setProperty(
          '--darker',
          adjustColor(data.defaultButtonColor, -20),
        );
      }
    }),
  );
};

const adjustColor = (hex: string, percent: number) => {
  // Convert hex to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Calculate new RGB values
  r = Math.min(255, Math.max(0, r + (r * percent) / 100));
  g = Math.min(255, Math.max(0, g + (g * percent) / 100));
  b = Math.min(255, Math.max(0, b + (b * percent) / 100));

  // Convert back to hex
  let newHex =
    '#' +
    ('0' + Math.round(r).toString(16)).slice(-2) +
    ('0' + Math.round(g).toString(16)).slice(-2) +
    ('0' + Math.round(b).toString(16)).slice(-2);

  return newHex;
};
