import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ToastComponent } from '../toast/toast.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IToast, ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-toast-container',
  standalone: true,
  imports: [],
  templateUrl: './toast-container.component.html',
  styleUrl: './toast-container.component.css',
})
export class ToastContainerComponent {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  containerRef!: ViewContainerRef;

  constructor(private toastService: ToastService) {
    this.toastService.toastAdded
      .pipe(takeUntilDestroyed())
      .subscribe((toast) => {
        this.createToast(toast);
      });
  }

  createToast(toast: IToast) {
    const componentRef = this.containerRef.createComponent(ToastComponent);

    componentRef.instance.ref.set(componentRef);
    componentRef.instance.toast.set(toast);

    setTimeout(() => componentRef.instance.onClose(), toast.duration);
    setTimeout(() => componentRef.destroy(), toast.duration + 500);
  }
}
