import { UserRole } from '@/models/user-role.model';
import { Route } from '@angular/router';
import { roleGuard } from 'app/guards/auth.guard';

export const settingsRoute: Route = {
  path: 'settings',
  loadComponent: () =>
    import('../settings/settings.component').then((c) => c.SettingsComponent),
  data: {
    allowedRoles: [UserRole.SuperVPlaybookAdmin],
  },
  canActivate: [roleGuard],
  children: [
    {
      path: '',
      redirectTo: 'user-guide',
      pathMatch: 'full',
    },
    {
      path: 'user-guide',
      loadComponent: () =>
        import(
          '../settings/user-guide-settings/user-guide-settings.component'
        ).then((c) => c.UserGuideSettingsComponent),
    },
    {
      path: 'release-notifications',
      loadComponent: () =>
        import(
          '../settings/release-notifications/release-notifications.component'
        ).then((c) => c.ReleaseNotificationsComponent),
    },
    {
      path: 'application-version',
      loadComponent: () =>
        import(
          '../settings/application-version/application-version.component'
        ).then((c) => c.ApplicationVersionComponent),
    },
  ],
};
