import { Injectable } from '@angular/core';
import { ToastService } from 'app/service/toast.service';
@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private hasInitialized = false;

  constructor(private toastService: ToastService) {
    this.initializeListeners();
  }

  private initializeListeners() {
    if (this.hasInitialized) return;

    this.hasInitialized = true;

    window.addEventListener('online', () => {
      this.toastService.show('Internet connection restored', 'toast-success');
    });

    window.addEventListener('offline', () => {
      this.toastService.show('No internet connection, please check your connection and try again.', 'toast-warning');
    });
  }
}
