import { Routes } from '@angular/router';
import { authGuard } from 'app/guards/auth.guard';

export const playbookViewerRoutes: Routes = [
  {
    path: 'admin/company/:companyId/playbooks/:playbookId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
  {
    path: 'app/playbooks/:playbookId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
  {
    path: 'admin/company/:companyId/templates/playbook-template/:playbookId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
  {
    path: 'app/templates/playbook-template/:playbookId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
  {
    path: 'admin/company/:companyId/templates/page-template/:pageTemplateId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
  {
    path: 'app/templates/page-template/:pageTemplateId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
  // super admin playbook viewer routes
  {
    path: 'admin/templates/playbook-template-groups/:groupId/:playbookId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
  {
    path: 'admin/templates/page-template-groups/:groupId/:pageTemplateId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/playbook-viewer/playbook-viewer.component'
      ).then((c) => c.PlaybookViewerComponent),
  },
];
