import { Routes } from '@angular/router';
import { authGuard } from 'app/guards/auth.guard';

export const conversationViewerRoutes: Routes = [
  {
    path: 'admin/company/:companyId/templates/conversation-template/:conversationTemplateId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/conversation-template-viewer/conversation-template-viewer.component'
      ).then((c) => c.ConversationTemplateViewerComponent),
  },
  {
    path: 'app/templates/conversation-template/:conversationTemplateId',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './playbook-listing/conversation-template-viewer/conversation-template-viewer.component'
      ).then((c) => c.ConversationTemplateViewerComponent),
  },
];
