import { Route } from '@angular/router';
import { UserRole } from '@/models/user-role.model';
import { roleGuard } from '../../../guards/auth.guard';

export const peopleRoute: Route = {
  path: 'admins/people',
  loadComponent: () =>
    import(
      '@/admin/pages/admin-people-container/admin-people-container.component'
    ).then((c) => c.AdminPeopleContainerComponent),
  data: {
    allowedRoles: [UserRole.SuperVPlaybookAdmin],
  },
  children: [
    {
      path: '',
      loadComponent: () =>
        import('@/admin/pages/admin-overview/admin-overview.component').then(
          (c) => c.AdminOverviewComponent,
        ),
    },
    {
      path: 'companies',
      loadComponent: () =>
        import(
          '@/admin/pages/profiles-overview/profiles-overview.component'
        ).then((c) => c.ProfilesOverviewComponent),
    },
    {
      path: 'user-levels',
      loadComponent: () =>
        import('@/admin/pages/user-levels/user-levels.component').then(
          (c) => c.UserLevelsComponent,
        ),
    },
    {
      path: 'reports',
      loadComponent: () =>
        import('@/admin/pages/reports/reports.component').then(
          (c) => c.ReportsComponent,
        ),
    },
  ],
  canActivate: [roleGuard],
};
