<div
  class="login-wrapper"
  [style.background-image]="'url(assets/CVI-vPlaybook-1920x1080.png)'"
>
  <div class="wrap_all">
    <div class="section">
      <div class="container">
        <div class="login-container">
          <div class="graphic">
            <img src="assets/CVI-vPlaybook-Logo-RGB-White-Hi-Res.png" class="companyLogo"/>
            <div>
              <h2>
                <strong>{{ currentError.title }}</strong>
              </h2>
              <div class="footer-links">
                <p>
                  Contact the vPlaybook Support Team at
                  <a
                    href="mailto:support@vplaybook.com"
                    class="login-redirect"
                    >support&#64;vplaybook.com</a
                  >, if assistance is needed.
                </p>
              </div>
            </div>
          </div>
          <div class="form-container">
            <div>
              <p class="white mb-4">
                {{ currentError.message }}<br />
                @if (currentError.showLoginLink) {
                  Do you need to log in as someone else?
                }
              </p>
              <div class="card-rounded-right">
                <button
                  class="btn-default primary ml-auto {{ buttonClass }}"
                  (click)="redirectToBackPage()"
                >
                  {{ !isLocationError && !isServerError && !isNotFound ? 'Log In' : 'Back' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
