<router-outlet></router-outlet> <app-toast-container></app-toast-container>
<a
  [tooltip]="'Provide Beta feedback'"
  [tooltipBg]="'#fff'"
  [tooltipPosition]="'left'"
  class="absolute bottom-6 right-6"
  href="mailto:feedback@vplaybook.com?subject=vPlaybook Beta Feedback"
  target="_blank"
>
  <button class="btn-default small w-10 h-10">
    <app-font-icon [icon]="'feedback'" />
  </button>
</a>
