import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StorageService } from '../helpers/storage/storage.service';
import { UserRole } from '@/models/user-role.model';
import { ToastService } from '@/service/toast.service';

export const authGuard: CanActivateFn = (route, state) => {
  const userLoggedIn = inject(StorageService).isLoggedIn();
  if (!userLoggedIn) {
    inject(Router).navigate(['/auth/login']);
  }
  return true;
};

export const alreadyLoggedInGuard: CanActivateFn = (route, state) => {
  const userLoggedIn = inject(StorageService).isLoggedIn();
  if (userLoggedIn) {
    inject(Router).navigate(['/admin/companies']);
  }
  return true;
};

export const roleGuard: CanActivateFn = (route, state) => {
  const userRole = inject(StorageService).getUserRole();
  const allowedRoles: UserRole[] = route.data['allowedRoles'] as UserRole[];

  if (!allowedRoles) {
    console.log('Allowed roles is null!');
    inject(ToastService).show('Access denied!');
    return false;
  }

  if (!allowedRoles.includes(userRole)) {
    if (
      [
        UserRole.SuperVPlaybookAdmin,
        UserRole.StandardVPlaybookAdmin,
        UserRole.CompanyReader,
      ].includes(userRole)
    ) {
      inject(Router).navigate(['/admin/companies']);
    } else {
      inject(Router).navigate(['/app/playbooks']);
    }
  }

  return true;
};

export const learningCenterRedirectGuard: CanActivateFn = (route, state) => {
  const userRole = inject(StorageService).getUserRole();

  if (
    [
      UserRole.SuperVPlaybookAdmin,
      UserRole.StandardVPlaybookAdmin,
      UserRole.Administrator,
      UserRole.SalesManager,
    ].includes(userRole)
  ) {
    const tree = inject(Router).createUrlTree([`${state.url}/management`]);
    return tree;
  } else {
    const tree = inject(Router).createUrlTree([`${state.url}/user`]);
    return tree;
  }
};
