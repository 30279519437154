import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IdentityService } from 'app/http/identity/identity.service';
import { ERROR_MESSAGES, ErrorConfig } from './error-page.types';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  isLocationError = false;
  isServerError = false;
  isNotFound = false;
  currentError: ErrorConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private identityService: IdentityService
  ) {}

  ngOnInit() {
    this.isLocationError = this.router.url.includes('location-error');
    this.isServerError = this.route.snapshot.queryParams['type'] === 'server-error';
    this.isNotFound = this.route.snapshot.data['type'] === 'not-found';

    if (this.isLocationError) {
      this.currentError = ERROR_MESSAGES['location'];
    } else if (this.isServerError) {
      this.currentError = ERROR_MESSAGES['server'];
    } else if (this.isNotFound) {
      this.currentError = ERROR_MESSAGES['notFound'];
    } else {
      this.currentError = ERROR_MESSAGES['permission'];
    }
  }

  redirectToBackPage() {
    if (!this.isLocationError && !this.isServerError && !this.isNotFound) {
      this.logout();
    } else {
      this.location.back();
    }
  }

  logout() {
    this.identityService.logout();
  }

  get buttonClass(): string {
    return !this.isLocationError && !this.isServerError && !this.isNotFound
      ? 'login-button'
      : 'back-button';
  }
}

