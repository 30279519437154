import { IProfileDetailsResponse } from '@/api/profile/profile.model';
import { ProfileService } from '@/api/profile/profile.service';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { tap } from 'rxjs';

export const profileResolver: ResolveFn<IProfileDetailsResponse> = () => {
  const profileService = inject(ProfileService);

  return profileService.getProfileDetails().pipe(
    tap((data: IProfileDetailsResponse) => {
      profileService.currentProfile$.set(data.Result);
    }),
  );
};
