// routes.ts
import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { companyRoutes } from "@/company/companyRoutes";
import { authRoute } from './features/auth/auth.routes';
import { adminRoute } from "@/admin/adminRoute";
import { playbookViewerRoutes } from '@/company/playbooks/playbook-viewer-routes';
import { conversationViewerRoutes } from '@/company/playbooks/conversation-viewer-routes';
import { companyResolver } from './resolvers/company.resolver';
import { profileResolver } from './resolvers/profile.resolver';
import { ErrorPageComponent } from './components/error-page/error-page.component';

interface ErrorPageData {
  hideNavBar?: boolean;
  type?: 'not-found' | 'unauthorized' | 'location-error';
}

const errorRoutes: Routes = [
  {
    path: 'location-error',
    component: ErrorPageComponent,
    data: {
      hideNavBar: true,
      type: 'location-error'
    } as ErrorPageData
  },
  {
    path: 'unauthorized-access',
    component: ErrorPageComponent,
    data: {
      hideNavBar: true,
      type: 'unauthorized'
    } as ErrorPageData
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: {
      hideNavBar: true,
      type: 'not-found'
    } as ErrorPageData
  }
];

const appRoutes: Routes = [
  {
    path: 'app/profile',
    loadComponent: () =>
      import('./features/profile/profile.component').then(
        (c) => c.ProfileComponent,
      ),
  },
  {
    path: 'app',
    loadComponent: () =>
      import('./features/company/company.component').then(
        (c) => c.CompanyComponent,
      ),
    resolve: {
      company: companyResolver,
    },
    children: companyRoutes,
  }
];

export const routes: Routes = [
  authRoute,
  ...playbookViewerRoutes,
  ...conversationViewerRoutes,
  {
    path: 'resourcedownload',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './features/company/playbooks/playbook-listing/playbook-viewer/resource-download/resource-download.component'
      ).then((c) => c.ResourceDownloadComponent),
  },
  {
    path: '',
    canActivate: [authGuard],
    resolve: {
      profile: profileResolver,
    },
    loadComponent: () =>
      import('./features/container/container.component').then(
        (c) => c.ContainerComponent,
      ),
    children: [
      ...adminRoute,
      ...appRoutes,
      ...errorRoutes,
    ],
  },
  // Fallback route for top level
  {
    path: '**',
    component: ErrorPageComponent,
    data: {
      hideNavBar: true,
      type: 'not-found'
    } as ErrorPageData,
  },
];
