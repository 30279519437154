import { Route } from '@angular/router';
import { alreadyLoggedInGuard } from '../../guards/auth.guard';

export const authRoute: Route = {
  path: 'auth',
  canActivate: [alreadyLoggedInGuard],
  loadComponent: () => import('./auth.component').then((c) => c.AuthComponent),
  children: [
    {
      path: 'login',
      loadComponent: () =>
        import('./login/login.component').then((c) => c.LoginComponent),
    },
    {
      path: 'forgot-password',
      loadComponent: () =>
        import('./forgot-password/forgot-password.component').then(
          (c) => c.ForgotPasswordComponent
        ),
    },
  ],
};
